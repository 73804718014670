import React from "react";
import "../App.css";
import logo from "../logo.png";
import gp from "../google-play-badge.png";

const HomePage: React.FunctionComponent<{}> = (props) => {
  return (
    <body className="Site">
      {/* <header>…</header> */}
      <main className="Site-content">
        <img src={logo} alt="logo" className="logo" />
        <h1>Untrap</h1>
        <a href="https://play.google.com/store/apps/details?id=com.ebelov.no_tow">
          <img className="google-play-btn" src={gp} alt="Get on Google Play" />
        </a>
      </main>
      <footer className="Site-footer">
        <p>Copyright 2021 Untrap</p>
        <span>
          <a href="/privacy">Privacy Policy</a> |{" "}
          <a href="tnc"> Terms & Conditions</a>
        </span>
      </footer>
    </body>
  );
};

export default HomePage;
