import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import HomePage from "./pages/home";
import PrivacyPolicyPage from "./pages/privacy-policy";
import TermsAndConditions from "./pages/tnc";
const Application: React.FunctionComponent<{}> = (props) => {
  return (
    <div>
      <Router>
        <Routes>
          <Route path={"/"} element={<HomePage />} />
          <Route path={"/tnc"} element={<TermsAndConditions />} />
          <Route path={"/privacy"} element={<PrivacyPolicyPage />} />
        </Routes>
      </Router>
    </div>
  );
};

export default Application;
