export default function TermsAndConditions() {
  return (
    <main>
      <section className='bg-light'>
        <div className='flex flex-col min-h-screen text-black layout'>
          <h1 className='mt-8 mb-4'>Untrap - Terms &amp; Conditions</h1>
          <p>
            By downloading or using the app, these terms will automatically
            apply to you – you should make sure therefore that you read them
            carefully before using the app. You’re not allowed to copy, or
            modify the app, any part of the app, or our trademarks in any way.
            You’re not allowed to attempt to extract the source code of the app,
            and you also shouldn’t try to translate the app into other
            languages, or make derivative versions. The app itself, and all the
            trade marks, copyright, database rights and other intellectual
            property rights related to it, still belong to Untrap.
          </p>
          <h2 className='mt-8 mb-4' id='basic-terms'>
            Basic Terms
          </h2>
          <ul className='list-disc ml-16'>
            <li>You are responsible for keeping your password secure.</li>
            <li>
              You may not use the Untrap service for any illegal or unauthorized
              purpose. International users agree to comply with all local laws
              regarding online conduct and acceptable content.
            </li>
            <li>
              You must not modify, adapt or hack Untrap or modify another
              website so as to falsely imply that it is associated with Untrap.
            </li>
            <li>
              You must not access Untrap&#39;s private API by any other means
              other than the Untrap application itself.
            </li>
            <li>
              You must not crawl, scrape, or otherwise cache any content from
              Untrap including but not limited to user profiles and photos.
            </li>
            <li>
              You must not, in the use of Untrap, violate any laws in your
              jurisdiction (including but not limited to copyright laws).
            </li>
            <li>
              You must not submit towing reports if you are directly or
              indirectly engaged in towing activity, provide towing services, or
              have access to or possess any information that puts you in a
              beneficial position compared to regular users of the Untrap
              application.
            </li>
            <li>
              Violation of any of these agreements will result in the
              termination of your Untrap account.
            </li>
          </ul>
          <p className='mt-8'>
            We are committed to ensuring that the app is as useful and
            efficient as possible. For that reason, we reserve the right to make
            changes to the app or to charge for its services, at any time and
            for any reason. We will never charge you for the app or its services
            without making it very clear to you exactly what you’re paying for.
          </p>
          <p className='mt-8'>
            The Untrap app stores and processes personal data that you have
            provided to us, in order to provide our Service. It’s your
            responsibility to keep your phone and access to the app secure. We
            therefore recommend that you do not jailbreak or root your phone,
            which is the process of removing software restrictions and
            limitations imposed by the official operating system of your device.
            It could make your phone vulnerable to malware/viruses/malicious
            programs, compromise your phone’s security features and it could
            mean that the Untrap app won’t work properly or at all.
          </p>
          <p className='mt-8'>
            The app does use third party services that declare their own Terms
            and Conditions.
          </p>
          <p className='mt-8'>
            Link to Terms and Conditions of third party service providers used
            by the app:
          </p>
          <ul className='list-disc ml-16 mt-2'>
            <li>
              <a href='https://firebase.google.com/terms'>Firebase Services</a>
            </li>
            <li>
              <a href='https://policies.google.com/terms'>
                Google Play Services
              </a>
            </li>
            <li>
              <a href='https://cloud.google.com/terms/'>
                Google Cloud Platform Services
              </a>
            </li>
            <li>
              <a href='https://developers.google.com/terms/'>Google APIs </a>
            </li>
            <li>
              <a href='https://firebase.google.com/terms/data-processing-terms/'>
                Firebase Data Processing{' '}
              </a>
            </li>
            <li>
              <a href='https://firebase.google.com/terms/analytics'>
                Google Analytics for Firebase{' '}
              </a>
            </li>
            <li>
              <a href='https://firebase.google.com/terms/crashlytics'>
                Firebase Crashlytics{' '}
              </a>
            </li>
          </ul>
          <p className='mt-8'>
            You should be aware that there are certain things that we
            will not take responsibility for. Certain functions of the app will
            require the app to have an active internet connection. The
            connection can be Wi-Fi, or provided by your mobile network
            provider, but we cannot take responsibility for the app
            not working at full functionality if you don’t have access to Wi-Fi,
            and you don’t have any of your data allowance left.
          </p>
          <p className='mt-8'>
            If you’re using the app outside of an area with Wi-Fi, you should
            remember that your terms of the agreement with your mobile network
            provider will still apply. As a result, you may be charged by your
            mobile provider for the cost of data for the duration of the
            connection while accessing the app, or other third party charges. In
            using the app, you’re accepting responsibility for any such charges,
            including roaming data charges if you use the app outside of your
            home territory (i.e. region or country) without turning off data
            roaming. If you are not the bill payer for the device on which
            you’re using the app, please be aware that we assume that you have
            received permission from the bill payer for using the app.
          </p>
          <p className='mt-8'>
            Along the same lines, we cannot always take responsibility
            for the way you use the app i.e. You need to make sure that your
            device stays charged, does not restrict notifications from the
            Untrap application, and does not have Do Not Disturb or Silent or
            similar mode enabled.
          </p>
          <p className='mt-8'>
            With respect to our responsibility for your use of the
            app, when you’re using the app, it’s important to bear in mind that
            although we endeavour to ensure that it is updated and correct at
            all times, we do rely on third parties to provide information to us
            so that we can make it available to you. We accepts no
            liability for any loss, direct or indirect, you experience as a
            result of relying wholly on this functionality of the app.
          </p>
          <p className='mt-8'>
            At some point, we may wish to update the app. The app is currently
            available on Android &amp; iOS – the requirements for both
            systems(and for any additional systems we decide to extend the
            availability of the app to) may change, and you’ll need to download
            the updates if you want to keep using the app. We do not
            promise that it will always update the app so that it is relevant to
            you and/or works with the Android &amp; iOS version that you have
            installed on your device. However, you promise to always accept
            updates to the application when offered to you, We may also wish to
            stop providing the app, and may terminate use of it at any time
            without giving notice of termination to you. Unless we tell you
            otherwise, upon any termination, (a) the rights and licenses granted
            to you in these terms will end; (b) you must stop using the app, and
            (if needed) delete it from your device.
          </p>
          <h2 className='mt-8 mb-4' id='changes-to-this-terms-and-conditions'>
            Changes to This Terms and Conditions
          </h2>
          <p>
            We may update our Terms and Conditions from time to time. Thus, you
            are advised to review this page periodically for any changes. We will
            notify you of any changes by posting the new Terms and Conditions on
            this page.
          </p>
          <p>These terms and conditions are effective as of 2021-04-25</p>
          <h2 className='mt-8 mb-4' id='contact-us'>
            Contact Us
          </h2>
          <p className='mb-16'>
            If you have any questions or suggestions about our Terms and
            Conditions, do not hesitate to contact us at feedback@untrap.net.
          </p>
        </div>
      </section>
    </main>
  );
}
