export default function PrivacyPolicyPage() {
  return (
    <main>
      <section className='bg-light'>
        <div className='flex flex-col  min-h-screen text-black layout'>
          <h1 className='mt-8'>Untrap - Privacy Policy</h1>
          <p className='mt-8'>
            We built the Untrap app as a Freemium app. This SERVICE is
            provided at no cost and is intended for use as is.
            This page is used to inform visitors regarding our policies with the
            collection, use, and disclosure of Personal Information if anyone
            decided to use our Service. If you choose to use our Service, then you
            agree to the collection and use of information in relation to this
            policy. The Personal Information that we collect is used for
            providing and improving the Service. We will not use or share your
            information with anyone except as described in this Privacy Policy.
          </p>
          <p className='mt-8'>
            The terms used in this Privacy Policy have the same meanings as in
            our Terms and Conditions, which is accessible at untrap.net unless
            otherwise defined in this Privacy Policy.
          </p>
          <h2 id='information-collection-and-use' className='mt-8 mb-4'>
            Information Collection and Use
          </h2>
          <ul className='list-disc ml-16'>
            <li>
              <p className='mt-2'>
                <strong>Personal Information</strong>. For a better experience,
                while using our Service, we may require you to provide us with
                certain personally identifiable information, including but not
                limited to the full name, email addresses, phone numbers,
                vehicle license plate numbers, photographic images of vehicles,
                Internet Protocol (IP) addresses.
              </p>
            </li>
            <li>
              <p className='mt-2'>
                <strong>Your usage</strong>. We collect information about how
                you use our Service, such as the types of content you view or
                engage with; the features you use; the actions you take; and the
                time, frequency and duration of your activities. We also collect
                information about how you use features like the camera.
              </p>
            </li>
            <li>
              <p className='mt-2'>
                <strong>
                  Things others do and information they provide about you
                </strong>
                . We also receive and analyze content and information that other
                people provide when they use our Service. This can include
                information about you, such as your vehicle&#39;s license plate
                number and photographic images of your vehicle.
              </p>
            </li>
          </ul>
          <h2 id='device-information' className='mt-8 mb-4'>
            Device Information
          </h2>
          <p>Information we obtain from you device includes:</p>
          <ul className='list-disc ml-16'>
            <li>
              <p className='mt-2'>
                <strong>Device attributes</strong>: information such as the
                operating system, hardware and software versions, battery level,
                signal strength.
              </p>
            </li>
            <li>
              <p className='mt-2'>
                <strong>Device operations</strong>: information about operations
                and behaviors performed on the device, such as whether a window
                is foregrounded or backgrounded, or mouse movements (which can
                help distinguish humans from bots).
              </p>
            </li>
            <li>
              <p className='mt-2'>
                <strong>Identifiers</strong>: unique identifiers, device IDs,
                and other identifiers, such as from games, apps or accounts you
                use.
              </p>
            </li>
            <li>
              <p className='mt-2'>
                <strong>Data from device settings</strong>: information you
                allow us to receive through device settings you turn on, such as
                access to your camera or photos.
              </p>
            </li>
            <li>
              <p className='mt-2'>
                <strong>Network and connections</strong>: information such as
                the name of your mobile operator or ISP, language, time zone,
                mobile phone number, IP address, and connection speed.
              </p>
            </li>
          </ul>
          <p className='mt-8'>
            The app does use third party services that may collect information
            used to identify you. Link to privacy policy of third party service
            providers used by the app:
          </p>
          <ul className='list-disc ml-16 mt-4'>
            <li>
              <a href='https://www.google.com/policies/privacy/'>
                Google Play Services
              </a>
            </li>
            <li>
              <a href='https://firebase.google.com/policies/analytics'>
                Google Analytics for Firebase
              </a>
            </li>
            <li>
              <a href='https://firebase.google.com/support/privacy/'>
                Firebase Crashlytics
              </a>
            </li>
          </ul>
          <h2 id='log-data' className='mt-8 mb-4'>
            Log Data
          </h2>
          <p>
            We want to inform you that whenever you use our Service, in a case of
            an error in the app we collect data and information (through third
            party products) on your phone called Log Data. This Log Data may
            include information such as your device Internet Protocol (“IP”)
            address, device name, operating system version, the configuration of
            the app when utilizing our Service, the time and date of your use of
            the Service, and other statistics.
          </p>
          <h2 id='service-providers' className='mt-8 mb-4'>
            Service Providers
          </h2>
          <p>
            We may employ third-party companies and individuals due to the
            following reasons:
          </p>
          <ul className='list-disc ml-16 mt-4'>
            <li>To facilitate our Service;</li>
            <li>To provide the Service on our behalf;</li>
            <li>To perform Service-related services; or</li>
            <li>To assist us in analyzing how our Service is used.</li>
          </ul>
          <p className='mt-8'>
            We want to inform users of this Service that these third parties have
            access to your Personal Information. The reason is to perform the
            tasks assigned to them on our behalf. However, they are obligated
            not to disclose or use the information for any other purpose.
          </p>
          <h2 id='security' className='mt-8 mb-4'>
            Security
          </h2>
          <p>
            We value your trust in providing us your Personal Information, thus
            we are striving to use commercially acceptable means of protecting
            it. But remember that no method of transmission over the internet,
            or method of electronic storage is 100% secure and reliable, and we
            cannot guarantee its absolute security.
          </p>
          <h2 id='links-to-other-sites' className='mt-8 mb-4'>
            Links to Other Sites
          </h2>
          <p>
            This Service may contain links to other sites. If you click on a
            third-party link, you will be directed to that site. Note that these
            external sites are not operated by us. Therefore, we strongly advise
            you to review the Privacy Policy of these websites. We have no
            control over and assume no responsibility for the content, privacy
            policies, or practices of any third-party sites or services.
          </p>
          <h2 id='children-s-privacy' className='mt-8 mb-4'>
            Children’s Privacy
          </h2>
          <p>
            These Services do not address anyone under the age of 13. We do not
            knowingly collect personally identifiable information from children
            under 13 years of age. In the case we discover that a child under 13
            has provided us with personal information, we immediately delete this
            from our servers. If you are a parent or guardian and you are aware
            that your child has provided us with personal information, please
            contact us so that we will be able to do necessary actions.
          </p>
          <h2 id='changes-to-this-privacy-policy' className='mt-8 mb-4'>
            Changes to This Privacy Policy
          </h2>
          <p>
            We may update our Privacy Policy from time to time. Thus, you are
            advised to review this page periodically for any changes. We will
            notify you of any changes by posting the new Privacy Policy on this
            page.
          </p>
          <p>This policy is effective as of 2021-04-25</p>
          <h2 id='contact-us' className='mt-8 mb-4'>
            Contact Us
          </h2>
          <p className='mb-12'>
            If you have any questions or suggestions about our Privacy Policy, do
            not hesitate to contact us at feedback@untrap.net.
          </p>
        </div>
      </section>
    </main>
  );
}
